<template>
    <div class="stake-box" :class="{ 'stake-mobile': isMobile }">
        <header>
            <div class="stake-logo">
                <a href="/">
                    <img v-if="isMobile" src="~@/assets/images/m-logo2.svg" alt="ClearStaking" />
                    <img v-else src="~@/assets/images/logo2.svg" alt="ClearStaking" />
                </a>
            </div>
            <wallet></wallet>
        </header>
        <div class="stake-main">
            <div class="stake-title">Stake CLH to Receive Rewards</div>
            <a-row class="stake-tab">
                <a-col align="center" :class="{ active: currentTab == '1' }" @click="stakeTabClick(1)">
                    <span>Stake</span>
                </a-col>
                <a-col align="center" :class="{ active: currentTab == '2' }" @click="stakeTabClick(2)">
                    <span>Position</span>
                </a-col>
            </a-row>
            <div v-show="currentTab == '1'" class="stake-content">
                <div class="stake-content-tab">
                    <ul>
                        <li
                            :class="{
                                active: product_id == product.product_id,
                            }"
                            @click="changePeriod(product.product_id)"
                            v-for="product in productList"
                            :key="product.product_id"
                        >
                            <div class="stake-content-div">
                                <div>
                                    <h3>{{ product.day }}-Day</h3>
                                    <p>
                                        <span class="stake-content-tooltip">
                                            APY
                                            <a-tooltip placement="bottom" title="APY is calculated against yesterday's total staking amount.">
                                                <img src="~@/assets/images/note-blue.png" class="mg-l4" width="12" height="12" />
                                            </a-tooltip>
                                        </span>
                                        <span class="stake-content-span">{{ product.apy > 0 ? product.apy + '%' : '-' }}</span>
                                    </p>
                                </div>
                                <div class="pool-size">
                                    <label>Pool Size</label>
                                    <p>{{ product.balance | decimals }} CLH</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="stake-content-form">
                    <div class="stake-name">Stake</div>
                    <div class="wallet-balance">
                        <h3>Wallet Balance:</h3>
                        <p>
                            {{ walletBalance | decimal(18, 4) }}
                            <small>CLH</small>
                        </p>
                    </div>
                    <div class="trade-input">
                        <div class="trade-number">
                            <img src="~@/assets/images/logo1.svg" alt="Clear" />
                            <a-input-number :style="{ height: isMacos ? '28px' : 'auto' }" v-model="stakeAmount" :precision="4" type="number" @keydown="keydown" />
                            <div class="trade-max">
                                <span @click="stakeAmount = changeBalance(walletBalance)">Max</span>
                            </div>
                        </div>
                    </div>
                    <div class="stake-auto">
                        <div>
                            <a-checkbox @change="autoChange" :defaultChecked="true">
                                Automatic Reinvestment
                            </a-checkbox>
                            <span class="stake-content-tooltip">
                                <a-tooltip
                                    placement="bottom"
                                    title="Once the current staking period ends, your principals and rewards will be reinvested in the next circle of the staking period automatically."
                                >
                                    <img src="~@/assets/images/note-blue.png" width="12" height="12" />
                                </a-tooltip>
                            </span>
                        </div>
                    </div>
                    <div class="stake-button">
                        <a-button v-if="desireChainId != chainId || !account" @click="connectWallet()">
                            Connect Wallet
                        </a-button>
                        <a-button disabled="disabled" v-else-if="stakeAmount > changeBalance(walletBalance)">
                            Insufficient CLH Balance
                        </a-button>
                        <a-button v-else-if="stakeAmount === '.' || stakeAmount === '' || stakeAmount === null || stakeAmount == 0" disabled="disabled">
                            Enter an amount
                        </a-button>
                        <a-button v-else-if="stakeAmount < 1" disabled="disabled">
                            {{ less1 }}
                        </a-button>
                        <a-button v-else @click="clickStake" :loading="connectedLoading">
                            Stake
                        </a-button>
                    </div>
                    <div class="stake-date">
                        <label>Maturity Date (UTC)</label>
                        <p :class="{ auto: autoChecked }">
                            {{ addDay(product_id) | timeFormat('MMM DD, YYYY') }}
                        </p>
                    </div>
                </div>
            </div>
            <a-config-provider>
                <template #renderEmpty> </template>
                <div v-show="currentTab == '2'" class="stake-content">
                    <div class="position-total">
                        <a-row :gutter="[0, { xs: 12, sm: 12, md: 12, lg: 20 }]">
                            <a-col :span="24">
                                <label>Balance</label>
                                <p class="color-000000">
                                    {{ positionData.balance | decimals }}
                                    <span class="clh-unit">CLH</span>
                                </p>
                            </a-col>
                            <a-col :span="12">
                                <label>Accumulated Rewards</label>
                                <p class="color-17B02F">
                                    {{ decimalsProfit(positionData.rewards) }}
                                    <span class="clh-unit">CLH</span>
                                </p>
                            </a-col>
                            <a-col :span="12">
                                <label>Latest Rewards</label>
                                <p class="color-17B02F">
                                    {{ decimalsProfit(positionData.last_rewards) }}
                                    <span class="clh-unit">CLH</span>
                                </p>
                            </a-col>
                        </a-row>
                    </div>
                    <div class="position-info" :class="{ 'position-info-all': isSelectAll }">
                        <div class="position-info-dl">
                            <div class="position-info-dt">
                                <div class="flex-box space-center" v-if="matured.data.length > 0" @click="matured.open = !matured.open">
                                    <img
                                        class="position-info-dt-arrow cursor"
                                        :class="{
                                            'arrow-rotate': !matured.open,
                                        }"
                                        src="~@/assets/images/arrow-up.png"
                                    />
                                </div>
                                <div class="position-info-title flex-box space-between">
                                    <h2>
                                        Matured Stakes<span class="mg-l4">({{ matured.length }})</span>
                                    </h2>
                                    <p v-if="matured.data.length > 1" @click="selectAllClick">
                                        {{ isSelectAll ? 'Cancel' : 'All' }}
                                    </p>
                                </div>
                            </div>
                            <a-list :loading="matured.loading" item-layout="horizontal" :data-source="matured.open ? matured.data : []" :split="false">
                                <div v-if="matured.open && matured.data.length < matured.length" slot="loadMore" :style="{ textAlign: 'center', height: '32px', lineHeight: '32px' }">
                                    <a-spin v-if="matured.loadingMore" />
                                    <a-button type="link" v-else @click="onLoadMore('matured', '1')">
                                        More
                                    </a-button>
                                </div>
                                <a-list-item slot="renderItem" slot-scope="item">
                                    <div class="flex-box">
                                        <div class="position-info-dd">
                                            <a-row
                                                :gutter="[
                                                    0,
                                                    {
                                                        xs: 8,
                                                        sm: 8,
                                                        md: 8,
                                                        lg: 14,
                                                    },
                                                ]"
                                            >
                                                <a-col :xs="8" :sm="8" :md="8" :lg="10" :xl="10">
                                                    <label>Stake Period</label>
                                                    <p class="color-000000">{{ item.invest_day }}-Day</p>
                                                </a-col>
                                                <a-col :span="8">
                                                    <label>Duration</label>
                                                    <p class="color-000000">
                                                        {{ item.invest_start | diffDay }}
                                                        Day
                                                    </p>
                                                </a-col>
                                                <a-col :xs="8" :sm="8" :md="8" :lg="6" :xl="6">
                                                    <div
                                                        :class="{
                                                            'text-right': isMobile,
                                                        }"
                                                    >
                                                        <label>Maturity Date</label>
                                                        <p class="color-000000">
                                                            {{ item.maturity_date | timeFormat('MMM DD, YYYY') }}
                                                        </p>
                                                    </div>
                                                </a-col>
                                                <a-col :xs="12" :sm="12" :md="12" :lg="10" :xl="10">
                                                    <label>Staked Principal</label>
                                                    <p class="color-000000">
                                                        {{ item.initial_capital | decimals
                                                        }}<span
                                                            class="
                                                                clh-unit
                                                                mg-l4
                                                            "
                                                            >CLH</span
                                                        >
                                                    </p>
                                                </a-col>
                                                <a-col :xs="12" :sm="12" :md="12" :lg="14" :xl="14">
                                                    <div
                                                        :class="{
                                                            'text-right': isMobile,
                                                        }"
                                                    >
                                                        <label>Rewards</label>
                                                        <p class="color-17B02F">
                                                            {{ decimalsProfit(item.profit) }}
                                                            <span
                                                                class="
                                                                    clh-unit
                                                                    mg-r4
                                                                "
                                                                >CLH</span
                                                            >
                                                            <a-tooltip v-if="item.profit > 0 && item.profit < 0.0001" placement="bottom" :title="item.profit">
                                                                <img src="~@/assets/images/note.png" />
                                                            </a-tooltip>
                                                        </p>
                                                    </div>
                                                </a-col>
                                            </a-row>
                                            <div class="position-button" v-if="!isSelectAll">
                                                <button @click="clickShowRestake(item)" type="primary">
                                                    Restake
                                                </button>
                                                <button @click="clickShowWithdraw(item)">
                                                    Withdraw
                                                </button>
                                            </div>
                                        </div>
                                        <div classs="position-checkbox" v-if="isSelectAll">
                                            <a-checkbox :defaultChecked="true" @change="maturedChange($event, item)"> </a-checkbox>
                                        </div>
                                    </div>
                                </a-list-item>
                            </a-list>
                            <div class="position-all-button" v-if="matured.open && isSelectAll && matured.data.length > 0">
                                <button type="primary" @click="clickShowAllRestake">
                                    Restake
                                </button>
                                <button @click="clickShowAllWithdraw">
                                    Withdraw
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="position-info">
                        <div class="position-info-dl">
                            <div class="position-info-dt">
                                <div class="flex-box space-center" v-if="staking.data.length > 0" @click="staking.open = !staking.open">
                                    <img
                                        class="position-info-dt-arrow cursor"
                                        :class="{
                                            'arrow-rotate': !staking.open,
                                        }"
                                        src="~@/assets/images/arrow-up.png"
                                    />
                                </div>
                                <div
                                    class="
                                        position-info-title
                                        flex-box
                                        space-between
                                    "
                                >
                                    <h2>
                                        Staked<span class="mg-l4">({{ staking.length }})</span>
                                    </h2>
                                </div>
                            </div>
                            <a-list :loading="staking.loading" item-layout="horizontal" :data-source="staking.open ? staking.data : []" :split="false">
                                <div
                                    v-if="staking.open && staking.data.length < staking.length"
                                    slot="loadMore"
                                    :style="{
                                        textAlign: 'center',
                                        height: '32px',
                                        lineHeight: '32px',
                                    }"
                                >
                                    <a-spin v-if="staking.loadingMore" />
                                    <a-button type="link" v-else @click="onLoadMore('staking', '2')">
                                        More
                                    </a-button>
                                </div>
                                <a-list-item slot="renderItem" slot-scope="item">
                                    <div class="position-info-dd">
                                        <a-row :gutter="[0, { xs: 8, sm: 8, md: 8, lg: 14 }]">
                                            <a-col :xs="8" :sm="8" :md="8" :lg="10" :xl="10">
                                                <label>Stake Period</label>
                                                <p class="color-000000">{{ item.invest_day }}-Day</p>
                                            </a-col>
                                            <a-col :span="8">
                                                <label>Duration</label>
                                                <p class="color-000000">
                                                    {{ item.invest_start | diffDay }}
                                                    Day
                                                </p>
                                            </a-col>
                                            <a-col :xs="8" :sm="8" :md="8" :lg="6" :xl="6">
                                                <div
                                                    :class="{
                                                        'text-right': isMobile,
                                                    }"
                                                >
                                                    <label>Maturity Date</label>
                                                    <p class="color-000000">
                                                        {{ item.maturity_date | timeFormat('MMM DD, YYYY') }}
                                                    </p>
                                                </div>
                                            </a-col>
                                            <a-col :xs="12" :sm="12" :md="12" :lg="10" :xl="10">
                                                <label>Staked Principal</label>
                                                <p class="color-000000">{{ item.initial_capital | decimals }}<span class="clh-unit mg-l4">CLH</span></p>
                                            </a-col>
                                            <a-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8">
                                                <div
                                                    :class="{
                                                        'text-right': isMobile,
                                                    }"
                                                >
                                                    <label>Rewards</label>
                                                    <p class="color-17B02F">
                                                        {{ decimalsProfit(item.profit) }}
                                                        <span
                                                            class="
                                                                clh-unit
                                                                mg-r4
                                                            "
                                                            >CLH</span
                                                        >
                                                        <a-tooltip v-if="item.profit > 0 && item.profit < 0.0001" placement="bottom" :title="item.profit">
                                                            <img src="~@/assets/images/note.png" />
                                                        </a-tooltip>
                                                    </p>
                                                </div>
                                            </a-col>
                                            <a-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                                                <label>Cumulative ARR</label>
                                                <p class="color-17B02F">{{ item.apy }}%</p>
                                            </a-col>
                                        </a-row>
                                        <div class="position-switch">
                                            Automatic Reinvestment
                                            <a-switch :checked="item.auto_reinvestment == 1" @change="stakeAutoChange(item)" />
                                        </div>
                                    </div>
                                </a-list-item>
                            </a-list>
                        </div>
                    </div>
                    <div class="position-info history-info">
                        <div class="position-info-dl">
                            <div class="position-info-dt">
                                <div class="flex-box space-center" v-if="history.data.length > 0" @click="history.open = !history.open">
                                    <img
                                        class="cursor"
                                        :class="{
                                            'arrow-rotate': !history.open,
                                        }"
                                        src="~@/assets/images/arrow-up.png"
                                    />
                                </div>
                                <div
                                    class="
                                        position-info-title
                                        flex-box
                                        space-between
                                    "
                                >
                                    <h2>
                                        History<span class="mg-l4">({{ history.length }})</span>
                                    </h2>
                                </div>
                            </div>
                            <a-list :loading="history.loading" item-layout="horizontal" :data-source="history.open ? history.data : []" :split="false">
                                <div
                                    v-if="history.open && history.data.length > history.length"
                                    slot="loadMore"
                                    :style="{
                                        textAlign: 'center',
                                        height: '32px',
                                        lineHeight: '32px',
                                    }"
                                >
                                    <a-spin v-if="history.loadingMore" />
                                    <a-button type="link" v-else @click="onLoadMore('history', '3')">
                                        More
                                    </a-button>
                                </div>
                                <a-list-item slot="renderItem" slot-scope="item">
                                    <div class="position-info-dd">
                                        <a-row :gutter="[0, { xs: 8, sm: 8, md: 8, lg: 14 }]">
                                            <a-col :xs="8" :sm="8" :md="8" :lg="10" :xl="10">
                                                <label>Stake Period</label>
                                                <p class="color-000000">{{ item.invest_day }}-Day</p>
                                            </a-col>
                                            <a-col :span="8">
                                                <label>Duration</label>
                                                <p class="color-000000">
                                                    {{ item.invest_start | diffDay }}
                                                    Day
                                                </p>
                                            </a-col>
                                            <a-col :xs="8" :sm="8" :md="8" :lg="6" :xl="6">
                                                <div
                                                    :class="{
                                                        'text-right': isMobile,
                                                    }"
                                                >
                                                    <label>Maturity Date</label>
                                                    <p class="color-000000">
                                                        {{ item.maturity_date | timeFormat('MMM DD, YYYY') }}
                                                    </p>
                                                </div>
                                            </a-col>
                                            <a-col :xs="12" :sm="12" :md="12" :lg="10" :xl="10">
                                                <label>Staked Principal</label>
                                                <p class="color-000000">{{ item.initial_capital | decimals }}<span class="clh-unit mg-l4">CLH</span></p>
                                            </a-col>
                                            <a-col :xs="12" :sm="12" :md="12" :lg="14" :xl="14">
                                                <div
                                                    :class="{
                                                        'text-right': isMobile,
                                                    }"
                                                >
                                                    <label>Rewards</label>
                                                    <p class="color-17B02F">
                                                        {{ decimalsProfit(item.profit) }}
                                                        <span
                                                            class="
                                                                clh-unit
                                                                mg-r4
                                                            "
                                                            >CLH</span
                                                        >
                                                        <a-tooltip v-if="item.profit > 0 && item.profit < 0.0001" placement="bottom" :title="item.profit">
                                                            <img src="~@/assets/images/note.png" />
                                                        </a-tooltip>
                                                    </p>
                                                </div>
                                            </a-col>
                                        </a-row>
                                    </div>
                                </a-list-item>
                            </a-list>
                        </div>
                    </div>
                </div>
            </a-config-provider>
        </div>
        <div>
            <a-modal v-model="showAuto" :maskClosable="false" :centered="true" :footer="null" :width="isMobile ? '86%' : '354px'" class="stake_auto_modal" :class="{ m_stake_auto_modal: isMobile }">
                <div class="enable_staking_title">
                    <span v-if="autoReinvestment == 1">Automatic Reinvestment</span>
                    <span v-else>Cancel Reinvestment</span>
                </div>
                <div class="enable_staking_content">
                    <span v-if="autoReinvestment == 1"
                        >Once the current staking period ends, your principals and rewards will be reinvested in the next circle of the staking period automatically.</span
                    >
                    <span v-else>By canceling the reinvestment, your assets will be unstaked and available for withdrawal once the current staking period ends.</span>
                </div>
                <div class="enable_staking_btn_wrap">
                    <a-button class="enable_staking_btn" @click="changeAutoReInvestment()" :loading="connectedLoading">
                        Understand
                    </a-button>
                </div>
            </a-modal>
            <a-modal
                v-model="showWithdraw"
                :maskClosable="false"
                :mask="false"
                :centered="true"
                :footer="null"
                :width="isMobile ? '86%' : '572px'"
                class="stake_withdraw_modal"
                :class="{ m_stake_withdraw_modal: isMobile }"
            >
                <div class="enable_staking_title">Withdraw</div>
                <div class="enable_staking_content">
                    <div class="wallet-balance" v-if="!isSelectAll">
                        <h3>Total Amount:</h3>
                        <p>
                            {{ showTicketBalance | decimals }}
                            <small>CLH</small>
                        </p>
                    </div>
                    <div class="trade-input" :class="{ 'trade-input-all': isSelectAll }">
                        <div class="trade-number">
                            <img src="~@/assets/images/logo1.svg" alt="Clear" />
                            <a-input-number :style="{ height: isMacos ? '28px' : 'auto' }" v-model="withdrawAmount" :precision="4" :disabled="isSelectAll" type="number" @keydown="keydown" />
                            <div class="trade-max">
                                <span @click="withdrawAmount = parseFloat(showTicketBalance.toFixed(4))">Max</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="enable_staking_btn_wrap">
                    <a-button v-if="desireChainId != chainId || !account" @click="connectWallet()">
                        Connect Wallet
                    </a-button>
                    <a-button disabled="disabled" v-else-if="withdrawAmount > parseFloat(showTicketBalance.toFixed(4))">
                        Insufficient CLH Balance
                    </a-button>
                    <a-button v-else-if="withdrawAmount === '.' || withdrawAmount === '' || withdrawAmount === null || withdrawAmount == 0" disabled="disabled">
                        Enter an amount
                    </a-button>
                    <a-button v-else class="enable_staking_btn" @click="isSelectAll ? withdrawBatch(ticket_ids) : clickWithdraw()" :loading="connectedLoading">
                        Withdraw
                    </a-button>
                </div>
            </a-modal>
            <a-modal
                v-model="showStake"
                :maskClosable="false"
                :mask="false"
                :centered="true"
                :footer="null"
                :width="isMobile ? '86%' : '572px'"
                class="stake_stake_modal"
                :class="{ m_stake_stake_modal: isMobile }"
            >
                <div class="enable_staking_title">Stake Period</div>
                <div class="enable_staking_content">
                    <div class="stake-re-period">
                        <a-row :gutter="[24, { xs: 16, sm: 16, md: 16, lg: 24 }]">
                            <a-col :xs="12" :sm="12" :md="12" :lg="6" v-for="product in productList" :key="product.product_id">
                                <div
                                    class="stake-re-col"
                                    :class="{
                                        active: product_id == product.product_id,
                                    }"
                                    @click="changePeriod(product.product_id)"
                                >
                                    <h3>{{ product.day }}-Day</h3>
                                    <p>
                                        <span>{{ product.apy > 0 ? product.apy + '%' : '-' }}</span>
                                    </p>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                    <div class="wallet-balance" v-if="!isSelectAll">
                        <h3>Total Amount:</h3>
                        <p>
                            {{ showTicketBalance | decimals }}
                            <small>CLH</small>
                        </p>
                    </div>
                    <div class="trade-input" :class="{ 'trade-input-all': isSelectAll }">
                        <div class="trade-number">
                            <img src="~@/assets/images/logo1.svg" alt="Clear" />
                            <a-input-number :style="{ height: isMacos ? '28px' : 'auto' }" v-model="reStakeAmount" :precision="4" :disabled="isSelectAll" type="number" @keydown="keydown" />
                            <div class="trade-max">
                                <span @click="reStakeAmount = parseFloat(showTicketBalance.toFixed(4))">Max</span>
                            </div>
                        </div>
                    </div>
                    <div class="stake-auto">
                        <div>
                            <a-checkbox @change="autoChange" :defaultChecked="true">
                                Automatic Reinvestment
                            </a-checkbox>
                            <span class="stake-content-tooltip">
                                <a-tooltip
                                    placement="bottom"
                                    title="Once the current staking period ends, your principals and rewards will be reinvested in the next circle of the staking period automatically."
                                >
                                    <img src="~@/assets/images/note-blue.png" width="12" height="12" />
                                </a-tooltip>
                            </span>
                        </div>
                    </div>
                    <div class="stake-button">
                        <a-button v-if="desireChainId != chainId || !account" @click="connectWallet()">
                            Connect Wallet
                        </a-button>
                        <a-button disabled="disabled" v-else-if="reStakeAmount > parseFloat(showTicketBalance.toFixed(4))">
                            Insufficient CLH Balance
                        </a-button>
                        <a-button v-else-if="reStakeAmount === '.' || reStakeAmount === '' || reStakeAmount === null || reStakeAmount == 0" disabled="disabled">
                            Enter an amount
                        </a-button>
                        <a-button v-else-if="reStakeAmount < 1" disabled="disabled">
                            {{ less1 }}
                        </a-button>
                        <a-button v-else @click="isSelectAll ? reinvestBatch(ticket_ids) : clickReStake()" :loading="connectedLoading">
                            Stake
                        </a-button>
                    </div>
                    <div class="stake-date">
                        <label>Maturity Date (UTC)</label>
                        <p :class="{ auto: autoChecked }">
                            {{ addDay(product_id) | timeFormat('MMM DD, YYYY') }}
                        </p>
                    </div>
                </div>
            </a-modal>
        </div>
        <footer-pc v-if="!isMobile"></footer-pc>
        <footer-mobile v-else></footer-mobile>
    </div>
</template>

<script>
import wallet from './wallet';
import { mapState, mapGetters, mapActions } from 'vuex';
import BigNumber from 'bignumber.js';
import { MaxUint256, STAKING_CONTRACT } from '@/config/constants';
import { waitForTransaction } from '@/utils/trans';
import { setStorage, getStorage } from '@/utils';
import { ethers } from 'ethers';
import moment from 'moment';
import { productList, positions, positionsMore, updateAutoReinvestment, signature, signatureBatch } from '@/api';
import footerPc from './footerDesktop';
import footerMobile from './footerMobile';
let now = parseInt(new Date().valueOf() / 1000);

export default {
    name: 'stake',
    components: {
        wallet,
        footerPc,
        footerMobile,
    },
    data() {
        return {
            stakeAmount: '',
            withdrawAmount: '',
            less1: 'Amount < 1',
            reStakeAmount: '',
            productList: [],
            product_id: 1,
            isMacos: navigator.userAgent.includes('Macintosh'),
            positionData: [],
            currentTab: getStorage('currentTab') || '1',
            connectedLoading: false,
            WithdrawStatus: false,
            autoChecked: true,
            matured: {
                length: 0,
                loading: false,
                open: false,
                loadingMore: false,
                data: [],
            },
            staking: {
                length: 0,
                loading: false,
                open: false,
                loadingMore: false,
                data: [],
            },
            history: {
                length: 0,
                loading: false,
                open: false,
                loadingMore: false,
                data: [],
            },
            showAuto: false,
            showTicketId: 0,
            showWithdraw: false,
            showTicketBalance: BigNumber(0),
            showStake: false,
            isSelectAll: false,
            ticket_ids: [],
            allTicketBalance: BigNumber(0),
            autoReinvestment: 0,
        };
    },
    computed: {
        ...mapGetters(['chainName', 'chainType']),
        ...mapState({
            isMobile: (state) => state.isMobile,
            account: (state) => state.account,
            walletBalance: (state) => state.userBalance,
            chainId: (state) => state.chainId,
            desireChainId: (state) => state.desireChainId,
        }),
        changeBalance() {
            return function(val) {
                if (!val) {
                    return 0.0;
                }
                return Number(new BigNumber(val.toString()).div(new BigNumber(10).pow(18)).toFixed(3));
            };
        },
        decimalsProfit() {
            return function(val) {
                if (!val) {
                    return val === 0 ? '0.0000' : '-';
                }
                if (BigNumber(val).eq(0)) {
                    return '0';
                }
                if (val < 0.0001) {
                    return '< 0.0001';
                } else {
                    return new BigNumber(val.toString()).toFixed(4);
                }
            };
        },
    },
    filters: {
        decimal(num, decimals, showDecimals) {
            if (!num) {
                return BigNumber(0).toFixed(showDecimals);
            }
            return new BigNumber(num.toString()).div(new BigNumber(10).pow(decimals || 18)).toFixed(showDecimals);
        },
        diffDay(timestamp) {
            return Math.ceil((now - timestamp) / 86400);
        },
    },
    watch: {
        account: {
            immediate: true,
            handler: function(val) {
                if (!val) {
                    return;
                }
                this.getPositions();
            },
        },
        chainId: {
            immediate: true,
            handler: function(val) {
                if (val) {
                    this.getPositions();
                }
            },
        },
    },
    created() {
        this.getPositions();
        this.decideMobile();
    },
    methods: {
        ...mapActions(['connected', 'switchNetwork']),
        keydown(event) {
            if (event.keyCode === 189) {
                event.preventDefault();
            }
        },
        getPositions() {
            if (this.productList.length == 0 && !this.loaingProductList) {
                this.loaingProductList = true;
                console.log('get production list', this.productList.length);
                productList().then(({ data }) => {
                    this.loaingProductList = false;
                    this.productList = data;
                });
            }
            if (!this.account) {
                return;
            }
            if (!this.chainType) {
                return;
            }
            positions({
                address: this.account,
                chain_type: this.chainType,
            }).then(({ data, code, extra }) => {
                if (code == 0) {
                    this.positionData = extra;
                    this.matured.data = data.matured;
                    this.matured.length = extra.count.matured;
                    this.staking.data = data.staking;
                    this.staking.length = extra.count.staking;
                    this.history.data = data.history;
                    this.history.length = extra.count.history;
                    if (data.matured.length > 0) {
                        this.matured.open = true;
                    } else if (data.staking.length > 0) {
                        this.staking.open = true;
                    } else if (data.history.length > 0) {
                        this.history.open = true;
                    }
                }
            });
        },
        addDay(product_id) {
            let index = this.productList.findIndex((item) => item.product_id == product_id);
            if (index == -1) {
                return '-';
            }
            let day = this.productList[index]['day'];
            return now + day * 86400 + 86400;
        },
        clickShowRestake(item) {
            this.reStakeAmount = '';
            this.showStake = true;
            this.showTicketId = item.ticket_id;
            this.showTicketBalance = BigNumber(item.initial_capital).plus(item.profit);
        },
        clickShowWithdraw(item) {
            this.withdrawAmount = '';
            this.showWithdraw = true;
            this.showTicketId = item.ticket_id;
            this.showTicketBalance = BigNumber(item.initial_capital).plus(item.profit);
        },
        clickShowAllRestake() {
            console.log('clickShowAllRestake');
            this.showStake = true;
            this.showTicketBalance = this.allTicketBalance;
            this.reStakeAmount = parseFloat(this.allTicketBalance.toFixed(4));
        },
        clickShowAllWithdraw() {
            console.log('clickShowAllWithdraw');
            this.showWithdraw = true;
            this.showTicketBalance = this.allTicketBalance;
            this.withdrawAmount = parseFloat(this.allTicketBalance.toFixed(4));
        },
        changePeriod(val) {
            this.product_id = val;
        },
        onLoadMore(item, type) {
            this[item].loadingMore = true;
            positionsMore({
                address: this.account,
                chain_type: this.chainType,
                limit: '10',
                skip: this[item].data.length,
                type: type,
            })
                .then(({ data, code }) => {
                    if (code == 0) {
                        this[item].data = this[item].data.concat(data);
                    }
                })
                .finally(() => {
                    this[item].loadingMore = false;
                });
        },
        async connectWallet() {
            if (!this.account) {
                await this.connected();
            }
            if (this.chainId != this.desireChainId) {
                await this.switchNetwork(this.desireChainId);
            }
        },
        clickStake() {
            if (!this.stakeAmount) {
                this.$notification['error']({
                    message: 'Please input the correct value',
                });
                return;
            }
            if (this.chainType == 1) {
                this.stakeERC777();
                return;
            }
            this.connectedLoading = true;
            this.$store.state.erc20Contract
                .allowance(this.account, STAKING_CONTRACT)
                .then((amount) => {
                    let stakeAmount = this.stakeAmount;
                    stakeAmount = new BigNumber(stakeAmount).multipliedBy(1e18).toFixed();
                    if (BigNumber(amount.toString()).gte(stakeAmount)) {
                        this.stakeErc20();
                        return;
                    }
                    this.$store.state.erc20Contract
                        .approve(STAKING_CONTRACT, MaxUint256)
                        .then(async (tx) => {
                            let receipt = await this.$store.state.provider.waitForTransaction(tx.hash);
                            if (receipt.status != 0) {
                                this.stakeErc20();
                            } else {
                                this.connectedLoading = false;
                            }
                        })
                        .catch(() => {
                            this.connectedLoading = false;
                        });
                })
                .catch(() => {
                    this.connectedLoading = false;
                });
        },
        stakeErc20() {
            let amount = this.stakeAmount;
            amount = new BigNumber(amount).multipliedBy(1e18).toFixed();
            this.$store.state.stakingContract
                .stake(amount, this.product_id, this.autoChecked)
                .then((tx) => {
                    waitForTransaction(tx.hash)
                        .then((res) => {
                            this.connectedLoading = false;
                            if (res.status == 0) {
                                this.$notification['error']({
                                    message: 'Transaction Failed',
                                });
                            } else {
                                this.$notification['success']({
                                    message: 'Transaction Successed',
                                });
                                this.stakeAmount = '';
                            }
                            this.getPositions();
                        })
                        .catch(() => {
                            this.connectedLoading = false;
                            this.$notification['error']({
                                message: 'Transaction Failed',
                            });
                        });
                })
                .catch((err) => {
                    this.connectedLoading = false;
                    console.log(err);
                    this.$notification['error']({
                        message: 'Transaction Failed',
                    });
                });
        },
        clickReStake() {
            if (!this.reStakeAmount) {
                this.$notification['error']({
                    message: 'Please input the correct value',
                });
                return false;
            }
            let amount = this.reStakeAmount;
            if (BigNumber(this.showTicketBalance.toFixed(4)).eq(amount)) {
                amount = this.showTicketBalance.multipliedBy(1e18).toFixed();
            } else {
                amount = new BigNumber(amount).multipliedBy(1e18).toFixed();
            }
            this.connectedLoading = true;
            let i = this.matured.data.findIndex((item) => item.ticket_id == this.showTicketId);
            let item = this.matured.data[i];
            let profit = BigNumber(item.profit)
                .multipliedBy(1e18)
                .toFixed();
            signature({
                ticket_id: item.ticket_id,
            })
                .then(({ code, data }) => {
                    if (code !== 0) {
                        this.connectedLoading = false;
                        this.showStake = false;
                        this.$notification['error']({
                            message: 'Signature Failed',
                        });
                        return;
                    }
                    this.$store.state.stakingContract
                        .reStake(item.token_id, profit, amount, this.product_id, this.autoChecked, data)
                        .then((tx) => {
                            waitForTransaction(tx.hash)
                                .then((res) => {
                                    this.connectedLoading = false;
                                    this.showStake = false;
                                    if (res.status == 0) {
                                        this.$notification['error']({
                                            message: 'Transaction Failed',
                                        });
                                    } else {
                                        this.$notification['success']({
                                            message: 'Transaction Successed',
                                        });
                                    }
                                    this.getPositions();
                                })
                                .catch(() => {
                                    this.connectedLoading = false;
                                    this.showStake = false;
                                    this.$notification['error']({
                                        message: 'Transaction Failed',
                                    });
                                });
                        })
                        .catch((err) => {
                            this.connectedLoading = false;
                            this.showStake = false;
                            console.log(err);
                            this.$notification['error']({
                                message: 'Transaction Failed',
                            });
                        });
                })
                .catch((err) => {
                    console.log(err);
                    this.connectedLoading = false;
                    this.showStake = false;
                    this.$notification['error']({
                        message: 'Signature Failed',
                    });
                });
        },
        clickWithdraw() {
            if (!this.withdrawAmount) {
                this.$notification['error']({
                    message: 'Please input the correct value',
                });
                return false;
            }
            let amount = this.withdrawAmount;
            if (BigNumber(this.showTicketBalance.toFixed(4)).eq(amount)) {
                amount = this.showTicketBalance.multipliedBy(1e18).toFixed();
            } else {
                amount = new BigNumber(amount).multipliedBy(1e18).toFixed();
            }
            this.connectedLoading = true;
            let i = this.matured.data.findIndex((item) => item.ticket_id == this.showTicketId);
            let item = this.matured.data[i];
            let profit = BigNumber(item.profit)
                .multipliedBy(1e18)
                .toFixed();
            signature({
                ticket_id: item.ticket_id,
            }).then(({ code, data }) => {
                if (code !== 0) {
                    this.connectedLoading = false;
                    this.showWithdraw = false;
                    this.$notification['error']({
                        message: 'Signature Failed',
                    });
                    return;
                }
                this.$store.state.stakingContract
                    .withdraw(item.token_id, profit, amount, data)
                    .then((tx) => {
                        waitForTransaction(tx.hash)
                            .then((res) => {
                                this.connectedLoading = false;
                                this.showWithdraw = false;
                                if (res.status == 0) {
                                    this.$notification['error']({
                                        message: 'Transaction Failed',
                                    });
                                } else {
                                    this.$notification['success']({
                                        message: 'Transaction Successed',
                                    });
                                }
                                this.getPositions();
                            })
                            .catch(() => {
                                this.connectedLoading = false;
                                this.showWithdraw = false;
                                this.$notification['error']({
                                    message: 'Transaction Failed',
                                });
                            });
                    })
                    .catch((err) => {
                        this.connectedLoading = false;
                        this.showWithdraw = false;
                        console.log(err);
                        this.$notification['error']({
                            message: 'Transaction Failed',
                        });
                    });
            });
        },
        stakeERC777() {
            let amount = this.stakeAmount;
            amount = new BigNumber(amount).multipliedBy(1e18).toFixed();
            console.log({
                product_id: this.product_id,
                autoChecked: this.autoChecked,
            });
            let encodeData = ethers.utils.defaultAbiCoder.encode(['uint256', 'bool'], [this.product_id, this.autoChecked]);
            this.connectedLoading = true;
            this.$store.state.erc20Contract
                .send(STAKING_CONTRACT, amount, encodeData)
                .then((tx) => {
                    waitForTransaction(tx.hash)
                        .then((res) => {
                            this.connectedLoading = false;
                            if (res.status == 0) {
                                this.$notification['error']({
                                    message: 'Transaction Failed',
                                });
                            } else {
                                this.$notification['success']({
                                    message: 'Transaction Successed',
                                });
                                this.stakeAmount = '';
                            }
                            this.getPositions();
                        })
                        .catch(() => {
                            this.connectedLoading = false;
                            this.$notification['error']({
                                message: 'Transaction Failed',
                            });
                        });
                })
                .catch((err) => {
                    this.connectedLoading = false;
                    console.log(err);
                    this.$notification['error']({
                        message: 'Transaction Failed',
                    });
                });
        },
        reinvestBatch(ticket_ids) {
            ticket_ids = ticket_ids.sort((a, b) => a - b);
            this.connectedLoading = true;
            let token_ids = [];
            let profits = [];
            let amount = BigNumber(0);
            for (let i = 0; i < ticket_ids.length; i++) {
                let i = this.matured.data.findIndex((item) => item.ticket_id == ticket_ids[i]);
                let item = this.matured.data[i];
                let profit = BigNumber(item.profit)
                    .multipliedBy(1e18)
                    .toFixed();
                profits.push(profit);
                token_ids.push(item.token_id);
                amount = amount.plus(
                    BigNumber(item.initial_capital)
                        .plus(item.profit)
                        .multipliedBy(1e18)
                );
            }
            signatureBatch({
                ticket_ids: ticket_ids.join(','),
            }).then(({ code, data }) => {
                if (code !== 0) {
                    this.connectedLoading = false;
                    this.showStake = false;
                    this.$notification['error']({
                        message: 'Signature Failed',
                    });
                    return;
                }
                this.$store.state.stakingContract
                    .reStakeBatch(token_ids, profits, amount.toFixed(), this.product_id, this.autoChecked, data)
                    .then((tx) => {
                        waitForTransaction(tx.hash)
                            .then((res) => {
                                this.connectedLoading = false;
                                this.showStake = false;
                                this.isSelectAll = false;
                                if (res.status == 0) {
                                    this.$notification['error']({
                                        message: 'Transaction Failed',
                                    });
                                } else {
                                    this.$notification['success']({
                                        message: 'Transaction Successed',
                                    });
                                }
                                this.getPositions();
                            })
                            .catch(() => {
                                this.connectedLoading = false;
                                this.showStake = false;
                                this.$notification['error']({
                                    message: 'Transaction Failed',
                                });
                            });
                    })
                    .catch((err) => {
                        this.connectedLoading = false;
                        this.showStake = false;
                        console.log(err);
                        this.$notification['error']({
                            message: 'Transaction Failed',
                        });
                    });
            });
        },
        withdrawBatch(ticket_ids) {
            ticket_ids = ticket_ids.sort((a, b) => a - b);
            this.connectedLoading = true;
            let token_ids = [];
            let profits = [];
            let amount = BigNumber(0);
            for (let i = 0; i < ticket_ids.length; i++) {
                let i = this.matured.data.findIndex((item) => item.ticket_id == ticket_ids[i]);
                let item = this.matured.data[i];
                let profit = BigNumber(item.profit)
                    .multipliedBy(1e18)
                    .toFixed();
                profits.push(profit);
                token_ids.push(item.token_id);
                amount = amount.plus(
                    BigNumber(item.initial_capital)
                        .plus(item.profit)
                        .multipliedBy(1e18)
                );
            }
            signatureBatch({
                ticket_ids: ticket_ids.join(','),
            }).then(({ code, data }) => {
                if (code !== 0) {
                    this.connectedLoading = false;
                    this.showWithdraw = false;
                    this.$notification['error']({
                        message: 'Signature Failed',
                    });
                    return;
                }
                this.$store.state.stakingContract
                    .withdrawBatch(token_ids, profits, amount.toFixed(), data)
                    .then((tx) => {
                        waitForTransaction(tx.hash)
                            .then((res) => {
                                this.connectedLoading = false;
                                this.showWithdraw = false;
                                this.isSelectAll = false;
                                if (res.status == 0) {
                                    this.$notification['error']({
                                        message: 'Transaction Failed',
                                    });
                                } else {
                                    this.$notification['success']({
                                        message: 'Transaction Successed',
                                    });
                                }
                                this.getPositions();
                            })
                            .catch(() => {
                                this.connectedLoading = false;
                                this.showWithdraw = false;
                                this.$notification['error']({
                                    message: 'Transaction Failed',
                                });
                            });
                    })
                    .catch((err) => {
                        this.connectedLoading = false;
                        this.showWithdraw = false;
                        console.log(err);
                        this.$notification['error']({
                            message: 'Transaction Failed',
                        });
                    });
            });
        },
        decideMobile() {
            if (/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(navigator.userAgent)) {
                this.$store.dispatch('updateMobile', true);
            } else {
                this.$store.dispatch('updateMobile', false);
            }
        },
        autoChange(e) {
            this.autoChecked = e.target.checked;
        },
        stakeAutoChange(item) {
            this.autoReinvestment = item.auto_reinvestment ? '0' : '1';
            this.showAuto = true;
            this.showTicketId = item.ticket_id;
        },
        changeAutoReInvestment() {
            let i = this.staking.data.findIndex((item) => item.ticket_id == this.showTicketId);
            let item = this.staking.data[i];
            let chainName = this.chainName;
            let autoReinvestment = !(item.auto_reinvestment ? 1 : 0);
            let token_id = item.token_id;
            let datetime =
                moment()
                    .utc()
                    .format('YYYY-MM-DD HH:mm:ss') + ' UTC';
            const exampleMessage = `token_id:${token_id}\nchain:${chainName}\nautoReinvestment:${autoReinvestment}\ndatetime:${datetime}`;
            const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
            window.ethereum
                .request({
                    method: 'personal_sign',
                    params: [msg, this.account],
                })
                .then((signature) => {
                    updateAutoReinvestment({
                        signature,
                        token_id,
                        chain: chainName,
                        autoReinvestment: autoReinvestment,
                        datetime,
                    })
                        .then(({ code, msg }) => {
                            if (code == 0) {
                                this.showAuto = false;
                                this.staking.data[i]['auto_reinvestment'] = autoReinvestment ? '1' : '0';
                            } else {
                                this.$notification['error']({
                                    message: msg,
                                });
                            }
                        })
                        .catch(() => {
                            this.$notification['error']({
                                message: 'Failed',
                            });
                        });
                })
                .catch(() => {
                    this.showAuto = false;
                });
        },
        maturedChange(e, item) {
            const itemBalance = BigNumber(item.initial_capital).plus(item.profit);
            if (e.target.checked) {
                this.ticket_ids.push(item.ticket_id);
                this.allTicketBalance = this.allTicketBalance.plus(itemBalance);
            } else {
                const index = this.ticket_ids.indexOf(item.ticket_id);
                if (index > -1) {
                    this.ticket_ids.splice(index, 1);
                    this.allTicketBalance = this.allTicketBalance.minus(itemBalance);
                }
            }
        },
        selectAllClick() {
            this.isSelectAll = !this.isSelectAll;
            this.matured.data.forEach((item) => {
                if (this.isSelectAll) {
                    this.ticket_ids.push(item.ticket_id);
                    const itemBalance = BigNumber(item.initial_capital).plus(item.profit);
                    this.allTicketBalance = this.allTicketBalance.plus(itemBalance);
                } else {
                    this.ticket_ids = [];
                    this.allTicketBalance = BigNumber(0);
                }
            });
        },
        stakeTabClick(val) {
            this.currentTab = val;
            setStorage('currentTab', this.currentTab);
        },
    },
};
</script>
<style lang="scss">
.ant-tooltip-inner {
    background-color: #ffffff !important;
    color: #959595 !important;
}
.ant-tooltip-arrow::before {
    background-color: #ffffff !important;
}
.ant-input-number-handler-wrap {
    display: none !important;
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
    border: 1px solid transparent !important;
}
.ant-btn-link {
    color: rgba(33, 90, 233, 0.5) !important;
}
</style>
<style scoped lang="scss">
.clh-unit {
    font-size: 14px;
}
.arrow-rotate {
    transform: rotate(180deg);
    transition: 200ms;
}
.wallet-balance {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 20px;
    h3 {
        font-size: 14px;
        color: #8f97aa;
        line-height: 18px;
    }
    p {
        font-size: 18px;
        line-height: 18px;
        color: #000000;
        small {
            font-size: 14px;
        }
        img {
            padding-left: 20px;
        }
    }
}
.trade-input {
    margin-top: 12px;
    .trade-number {
        padding: 0 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        height: 50px;
        border: 1px solid #606060;
        border-radius: 4px;
        .ant-input-number {
            flex: 1;
            font-size: 20px;
            border: none !important;
            background: none;
            box-shadow: none;
            color: #000000;
        }
    }
    .trade-max {
        width: 48px;
        height: 24px;
        background: rgba(113, 135, 242, 0.3);
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-size: 16px;
            color: #7187f2;
            cursor: pointer;
        }
        div {
            margin-left: 22px;
            padding: 0 20px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 9px;
                width: 2px;
                height: 16px;
                background: #999999;
                opacity: 1;
            }
        }
    }
}
.stake-auto {
    margin-top: 12px;
    /deep/.ant-checkbox-inner {
        width: 12px;
        height: 12px;
        border-radius: 2px;
    }
    /deep/.ant-checkbox-checked {
        &:after {
            border: none;
            border-radius: 0px;
        }
        .ant-checkbox-inner {
            background: transparent;
            border-color: #7187f2;
            &:after {
                border: none;
                width: 8px;
                height: 8px;
                background: #7187f2;
                border-radius: 2px;
                left: 1px;
                top: 1px;
                transform: none;
                transition: none;
            }
        }
    }
    div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    label {
        font-size: 14px;
        color: #000000;
    }
    .stake-auto-period {
        width: 100%;
        height: 24px;
        p {
            font-size: 12px;
            color: #959595;
            letter-spacing: -0.4px;
            line-height: 24px;
            background: rgba(230, 230, 230, 0.3);
            border-radius: 2px;
        }
    }
    .stake-content-tooltip {
        cursor: pointer;
        img {
            display: block;
        }
    }
}
.stake-date {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    label {
        font-size: 14px;
        color: #8f97aa;
    }
    p {
        font-size: 14px;
        color: #000000;
        &.auto {
            color: #8f97aa;
        }
    }
}
.stake-button {
    button {
        border: none !important;
        background: none;
        width: 100%;
        height: 50px;
        margin-top: 24px;
        color: #000000;
        background: linear-gradient(90deg, #7b7cf2 0%, #2dcaed 100%);
        box-shadow: 8px 8px 0px 0px rgba(113, 135, 242, 0.2);
        font-size: 18px;
        font-family: AvertaStd-Semibold;
        position: relative;
        opacity: 0.8;
        &:hover,
        &:focus,
        &:active {
            opacity: 1;
        }
    }
    button[disabled] {
        color: rgba(0, 0, 0, 0.3);
        background: #d5d8e3;
        box-shadow: none;
    }
}
.position-all-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 24px;
    height: 58px;
    background: #ffffff;
    border-radius: 4px;
    button {
        border: none;
        background: none;
        padding: 0 14px;
        height: 26px;
        border-radius: 2px;
        border: 1px solid rgba(33, 90, 233, 0.2);
        font-size: 14px;
        font-family: AvertaStd-Semibold;
        color: rgba(33, 90, 233, 0.5);
        &:first-child {
            background: rgba(113, 135, 242, 0.7);
            color: #000000;
            margin-right: 16px;
            border: none;
        }
    }
}
.stake-box {
    // background-image: url('~@/assets/images/stake-bg.svg');
    // background-repeat: no-repeat;
    // background-position: top center;
    header {
        padding: 52px 7.5% 40px;
        display: flex;
        justify-content: space-between;
        .stake-logo {
            display: flex;
            align-items: center;
        }
    }
    .stake-main {
        width: 572px;
        margin: 0 auto;
        padding-top: 58px;
        padding-bottom: 100px;
        .stake-title {
            font-size: 30px;
            font-family: 'AvertaStd-Semibold';
            color: #000000;
            line-height: 30px;
            padding-bottom: 50px;
            text-align: center;
        }
        .stake-tab {
            display: flex;
            justify-content: center;
            .ant-col {
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                width: 160px;
                height: 50px;
                border: 1px solid #959595;
                border-radius: 4px;
                color: #959595;
                font-size: 18px;
                font-family: 'AvertaStd-Semibold';
                &.active {
                    background: rgba(113, 135, 242, 0.7);
                    color: #000000;
                    border: none;
                }
                &:last-child {
                    margin-left: 40px;
                }
                &:not(.active):hover {
                    border: 1px solid #7187f2;
                    color: #7187f2;
                }
            }
        }
        .stake-content {
            margin-top: 32px;
            .stake-content-tab {
                background: #ffffff;
                box-shadow: 12px 12px 0px 0px rgba(113, 135, 242, 0.2);
                border-radius: 4px;
                ul {
                    li {
                        cursor: pointer;
                        .stake-content-div {
                            background: #ffffff;
                            position: relative;
                            padding: 24px 40px;
                            border-radius: 4px;
                            border: 1px solid #000000;
                            border-bottom: none;
                            div {
                                display: flex;
                                justify-content: space-between;
                                h3 {
                                    font-size: 22px;
                                    font-family: 'AvertaStd-Semibold';
                                    color: #000000;
                                    line-height: 22px;
                                }
                                p {
                                    font-size: 14px;
                                    color: #8f97aa;
                                    line-height: 14px;
                                    display: flex;
                                    align-items: center;
                                    .stake-content-tooltip {
                                        display: flex;
                                        cursor: pointer;
                                    }
                                    .stake-content-span {
                                        font-size: 24px;
                                        font-family: 'AvertaStd-Bold';
                                        color: #000000;
                                        line-height: 24px;
                                        padding-left: 12px;
                                    }
                                    i {
                                        width: 12px;
                                        height: 12px;
                                        background: rgba(113, 135, 242, 0.3);
                                        border-radius: 12px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 12px;
                                        margin-left: 4px;
                                        color: #7187f2;
                                    }
                                }
                            }
                            .pool-size {
                                display: none;
                                label {
                                    font-size: 14px;
                                    padding-top: 16px;
                                    color: #8f97aa;
                                    display: block;
                                }
                                p {
                                    padding-top: 8px;
                                    font-size: 18px;
                                    font-family: 'AvertaStd-Semibold';
                                    color: #000000;
                                    line-height: 22px;
                                }
                            }
                        }
                        &:last-child {
                            .stake-content-div {
                                border: 1px solid #000000;
                            }
                        }
                        &.active {
                            width: 584px;
                            height: auto;
                            position: relative;
                            left: -6px;
                            z-index: 6;
                            border-bottom: 1px solid #000000;
                            border-radius: 4px;
                            background: #ffffff;
                            cursor: default;
                            .stake-content-div {
                                z-index: 8;
                                background: #e5e9ff;
                                padding: 24px 46px;
                            }
                            .pool-size {
                                display: block;
                            }
                            &:after {
                                content: '';
                                width: 100%;
                                height: 100%;
                                background: rgba(113, 135, 242, 0.7);
                                position: absolute;
                                top: 12px;
                                left: 12px;
                                z-index: -1;
                                border-radius: 4px;
                            }
                        }
                    }
                }
            }
            .stake-content-form {
                margin-top: 32px;
                border: 1px solid #707070;
                border-radius: 4px;
                padding: 30px 52px 30px 40px;
                background: #ffffff;
                box-shadow: 12px 12px 0px 0px rgba(113, 135, 242, 0.2);
                .stake-name {
                    font-size: 18px;
                    color: #000000;
                    line-height: 22px;
                }
                h3 {
                    color: #727272;
                    font-size: 18px;
                    line-height: 22px;
                }
                .wallet-balance {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    margin-top: 20px;
                    h3 {
                        font-size: 14px;
                        color: #8f97aa;
                        line-height: 14px;
                    }
                    p {
                        font-size: 18px;
                        line-height: 18px;
                        color: #000000;
                        img {
                            padding-left: 20px;
                        }
                    }
                }
                .trade-input {
                    margin-top: 12px;
                    .trade-number {
                        padding: 0 14px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 18px;
                        height: 50px;
                        border: 1px solid #606060;
                        border-radius: 4px;
                        .ant-input-number {
                            flex: 1;
                            font-size: 20px;
                            border: none !important;
                            background: none;
                            box-shadow: none;
                            color: #000000;
                        }
                    }
                    .trade-max {
                        width: 48px;
                        height: 24px;
                        background: rgba(113, 135, 242, 0.3);
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        span {
                            font-size: 16px;
                            color: #7187f2;
                            cursor: pointer;
                        }
                        div {
                            margin-left: 22px;
                            padding: 0 20px;
                            position: relative;
                            &:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 9px;
                                width: 2px;
                                height: 16px;
                                background: #999999;
                                opacity: 1;
                            }
                        }
                    }
                }
                .stake-period {
                    margin-top: 34px;
                    ul {
                        display: flex;
                        margin-top: 18px;
                        li {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 140px;
                            height: 60px;
                            cursor: pointer;
                            margin-right: 40px;
                            font-size: 20px;
                            color: #111111;
                            background: rgba(255, 255, 255, 0.39);
                            border: 1px solid #111111;
                            border-radius: 4px;
                            &.active {
                                color: #ffffff;
                                background: #111111;
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .stake-content-info {
                padding-top: 40px;
                width: 90%;
                margin: 0 auto;
                h3 {
                    font-size: 18px;
                    color: #727272;
                    line-height: 22px;
                }
                .stake-equation {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    color: #111111;
                    padding-top: 10px;
                    .stake-equation-left {
                        padding-right: 8px;
                        p {
                            padding: 10px 0;
                            text-align: center;
                            &:first-child {
                                border-bottom: 1px solid #111111;
                            }
                        }
                    }
                }
                .stake-note {
                    padding-top: 16px;
                    padding-bottom: 10px;
                    font-size: 16px;
                    line-height: 32px;
                    color: #111111;
                }
                ul {
                    font-size: 16px;
                    color: #111111;
                    li {
                        margin-top: 20px;
                        padding-left: 16px;
                        line-height: 32px;
                        position: relative;
                        &:before {
                            position: absolute;
                            content: '';
                            left: 0;
                            top: 10px;
                            width: 6px;
                            height: 6px;
                            background: #111111;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
        .position-info {
            margin-top: 32px;
            background: rgba(113, 135, 242, 0.05);
            border-radius: 4px;
            padding: 12px 40px;
            .position-info-dl {
                .position-info-dt {
                    .position-info-title {
                        h2 {
                            color: #000000;
                            font-size: 18px;
                            line-height: 22px;
                            span {
                                color: rgba(0, 0, 0, 0.3);
                            }
                        }
                        p {
                            padding: 0 8px;
                            height: 24px;
                            background: rgba(113, 135, 242, 0.3);
                            border-radius: 2px;
                            font-size: 16px;
                            color: #7187f2;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }
                }
                .position-info-dd {
                    margin-bottom: 24px;
                    width: 492px;
                    background: #ffffff;
                    box-shadow: 12px 12px 0px 0px #e2e6fc;
                    border-radius: 4px;
                    border: 1px solid #000000;
                    .ant-row {
                        padding: 20px 24px;
                        label {
                            color: #8f97aa;
                            font-size: 14px;
                            line-height: 20px;
                        }
                        p {
                            margin-top: 4px;
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                    .position-button {
                        padding: 12px 24px 12px 0;
                        border-top: 1px solid rgba(0, 0, 0, 0.1);
                        text-align: right;
                        button {
                            border: none;
                            background: none;
                            padding: 0 14px;
                            height: 26px;
                            border-radius: 2px;
                            border: 1px solid rgba(33, 90, 233, 0.2);
                            font-size: 14px;
                            font-family: AvertaStd-Semibold;
                            color: rgba(33, 90, 233, 0.5);
                            &:first-child {
                                background: rgba(113, 135, 242, 0.7);
                                color: #000000;
                                margin-right: 16px;
                                border: none;
                            }
                        }
                    }
                    .position-switch {
                        padding: 12px 24px 12px 0;
                        border-top: 1px solid rgba(0, 0, 0, 0.1);
                        text-align: right;
                        button {
                            width: 40px;
                            height: 20px;
                            border-radius: 10px;
                            opacity: 0.8;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            background-color: transparent;
                            margin-left: 8px;
                            &:after {
                                width: 16px;
                                height: 16px;
                                border-radius: 16px;
                                background: #ccced0;
                            }
                            &.ant-switch-checked {
                                &:after {
                                    background: #7187f2;
                                }
                            }
                        }
                    }
                }
            }
            /deep/.ant-list {
                .ant-list-items {
                    padding-top: 18px;
                    .ant-list-item {
                        border-bottom: none;
                        padding: 0;
                    }
                }
                .ant-list-empty-text {
                    padding: 0;
                }
            }
        }
        .position-info-all {
            /deep/.ant-checkbox-inner {
                width: 20px;
                height: 20px;
                border-radius: 10px;
            }
            /deep/.ant-checkbox-checked {
                &:after {
                    border: none;
                    border-radius: 0px;
                }
                .ant-checkbox-inner {
                    background: transparent;
                    border-color: #959595;
                    opacity: 0.8;
                    &:after {
                        border: none;
                        width: 16px;
                        height: 16px;
                        background: #7187f2;
                        border-radius: 8px;
                        left: 1px;
                        top: 1px;
                        transform: none;
                    }
                }
            }
            .position-info-dl {
                .position-info-dd {
                    width: 472px;
                    margin-right: 20px;
                }
            }
        }
        .history-info {
            /deep/.ant-list {
                opacity: 0.4;
            }
        }
        .position-total {
            background: #ffffff;
            padding: 24px 40px;
            border-radius: 4px;
            label {
                display: block;
                font-size: 14px;
                line-height: 14px;
                padding-bottom: 12px;
                color: #8f97aa;
            }
            p {
                font-size: 18px;
                font-family: AvertaStd-Semibold;
            }
        }
    }
}
.stake_modal {
    .enable_staking_title {
        color: #000;
        font-size: 16px;
        letter-spacing: 0;
        font-family: AvertaStd-Semibold;
    }
    .enable_staking_content {
        font-size: 16px;
        color: #8f97aa;
        line-height: 20px;
        text-align: left;
    }
    .enable_staking_btn_wrap {
        height: 64px;
        .enable_staking_btn {
            max-width: 230px;
            height: 64px;
            font-size: 20px;
        }
    }
}
.stake_auto_modal {
    /deep/.ant-modal-content {
        .ant-modal-body {
            padding: 24px 40px;
        }
    }
    .enable_staking_title {
        color: #000;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0;
        font-family: AvertaStd-Semibold;
        margin: 0;
        text-align: center;
    }
    .enable_staking_content {
        font-size: 16px;
        color: #8f97aa;
        line-height: 20px;
        text-align: left;
        margin: 16px 0 0 0;
    }
    .enable_staking_btn_wrap {
        margin-top: 16px;
        height: 40px;
        .enable_staking_btn {
            width: 100%;
            height: 40px;
            background: rgba(113, 135, 242, 0.5);
            border-radius: 4px;
            color: #000000;
            font-size: 16px;
            font-family: AvertaStd-Semibold;
        }
    }
}
.stake_withdraw_modal {
    .enable_staking_title {
        font-size: 16px;
        line-height: 22px;
        font-family: AvertaStd-Semibold;
        color: #000000;
    }
    .enable_staking_btn_wrap {
        text-align: center;
        button {
            border: none !important;
            background: none;
            width: 240px;
            height: 50px;
            margin-top: 24px;
            color: #000000;
            background: linear-gradient(90deg, #7b7cf2 0%, #2dcaed 100%);
            box-shadow: 8px 8px 0px 0px rgba(113, 135, 242, 0.2);
            font-size: 18px;
            font-family: AvertaStd-Semibold;
            position: relative;
            opacity: 0.8;
            &:hover,
            &:focus,
            &:active {
                opacity: 1;
            }
        }
        button[disabled] {
            color: rgba(0, 0, 0, 0.3);
            background: #d5d8e3;
            box-shadow: none;
        }
    }
    .trade-input {
        margin-top: 12px;
        &.trade-input-all {
            margin-top: 30px;
            opacity: 0.4;
            background: #d5d8e3;
            .trade-max {
                display: none;
            }
        }
    }
    /deep/.ant-modal-content {
        .ant-modal-body {
            border-radius: 4px;
            border: 1px solid #000000;
            padding: 20px 40px 24px;
            box-shadow: 12px 12px 0px 0px #e2e6fc;
        }
    }
}
.stake_stake_modal {
    .enable_staking_title {
        font-size: 14px;
        color: #8f97aa;
        line-height: 20px;
    }
    .stake-re-period {
        padding-top: 12px;
        .stake-re-col {
            height: 66px;
            border-radius: 10px;
            border: 1px solid #7187f2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h3 {
                font-size: 16px;
                line-height: 20px;
                font-family: AvertaStd-Semibold;
                color: #000000;
            }
            p {
                font-size: 12px;
                line-height: 18px;
                color: #8f97aa;
                padding-top: 4px;
                span {
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #000000;
                    margin-left: 4px;
                }
            }
            &.active {
                background: rgba(113, 135, 242, 0.5);
                p {
                    color: #ffffff;
                }
            }
        }
    }
    .wallet-balance {
        margin-top: 32px;
    }
    .trade-input {
        margin-top: 12px;
        &.trade-input-all {
            margin-top: 30px;
            opacity: 0.4;
            background: #d5d8e3;
            .trade-max {
                display: none;
            }
        }
    }
    /deep/.ant-modal-content {
        .ant-modal-body {
            border-radius: 4px;
            border: 1px solid #000000;
            padding: 20px 40px;
            box-shadow: 12px 12px 0px 0px #e2e6fc;
        }
    }
}
.stake-mobile {
    background-image: url('~@/assets/images/m-stake-bg.svg');
    .clh-unit {
        font-size: 0.24rem;
    }
    .stake-auto {
        margin-top: 0.16rem;
        label {
            font-size: 0.24rem;
        }
        .stake-auto-period {
            width: 100%;
            height: 24px;
            p {
                font-size: 12px;
                color: #959595;
                letter-spacing: -0.4px;
                line-height: 24px;
                background: rgba(230, 230, 230, 0.3);
                border-radius: 2px;
            }
        }
        .stake-content-tooltip {
            cursor: pointer;
            img {
                display: block;
            }
        }
    }
    .stake-date {
        margin-top: 0.24rem;
        label {
            font-size: 0.24rem;
        }
        p {
            font-size: 0.24rem;
        }
    }
    .stake-button {
        button {
            height: 0.72rem;
            margin-top: 0.48rem;
            box-shadow: 0.1rem 0.1rem 0 0 rgba(113, 135, 242, 0.2);
            font-size: 0.32rem;
        }
    }
    header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: #f4f6fe;
        z-index: 99;
        padding: 0.44rem;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .stake-logo {
            img {
                width: 2.4rem;
                height: 0.52rem;
            }
        }
        /deep/.ant-btn {
            width: 2.36rem;
            height: 0.6rem;
            font-size: 0.28rem;
            padding: 0;
        }
        /deep/.change-network {
            width: 0.6rem;
            height: 0.6rem;
            margin-right: 0.16rem;
            .ant-select-selection--single {
                height: 0.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                .ant-select-selection__rendered {
                    margin: 0;
                    line-height: normal;
                    align-items: center;
                }
                .ant-select-dropdown-menu-item {
                    padding: 0;
                }
                .ant-select-arrow {
                    right: 0;
                }
            }
        }
    }
    .stake-main {
        width: auto;
        padding: 1.64rem 0 0;
        .stake-title {
            font-size: 0.4rem;
            line-height: 1;
            padding-bottom: 0.48rem;
        }
        .stake-tab {
            .ant-col {
                width: 2.08rem;
                height: 0.64rem;
                font-size: 0.32rem;
                &:last-child {
                    margin-left: 0.68rem;
                }
            }
        }
        .stake-content {
            margin-top: 0.32rem;
            .stake-content-tab {
                margin: 0 0.5rem 0 0.4rem;
                box-shadow: 0.12rem 0.12rem 0 0 rgba(113, 135, 242, 0.2);
                ul {
                    li {
                        .stake-content-div {
                            padding: 0.36rem 0.48rem;
                            div {
                                h3 {
                                    font-size: 0.32rem;
                                    line-height: 0.4rem;
                                }
                                p {
                                    font-size: 0.24rem;
                                    line-height: 0.24rem;
                                    .stake-content-span {
                                        font-size: 0.32rem;
                                        line-height: 0.32rem;
                                        padding-left: 0.24rem;
                                    }
                                }
                            }
                            .pool-size {
                                display: none;
                                label {
                                    font-size: 0.24rem;
                                    padding-top: 0.28rem;
                                }
                                p {
                                    padding-top: 0.16rem;
                                    font-size: 0.28rem;
                                    line-height: 0.34rem;
                                }
                            }
                        }
                        &.active {
                            width: 6.76rem;
                            .stake-content-div {
                                padding: 0.36rem 0.56rem;
                            }
                            .pool-size {
                                display: block;
                            }
                            &:after {
                                top: 0.12rem;
                                left: 0.12rem;
                            }
                        }
                    }
                }
            }
            .stake-content-form {
                margin: 0.32rem 0.5rem 0.78rem 0.4rem;
                padding: 0.4rem 0.48rem;
                box-shadow: 0.12rem 0.12rem 0 0 rgba(113, 135, 242, 0.2);
                .stake-name {
                    font-size: 0.32rem;
                    line-height: 0.4rem;
                }
                .wallet-balance {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    margin-top: 0.32rem;
                    h3 {
                        font-size: 0.24rem;
                        line-height: 0.24rem;
                    }
                    p {
                        font-size: 0.28rem;
                        line-height: 0.32rem;
                        small {
                            font-size: 0.24rem;
                        }
                    }
                }
                .trade-input {
                    margin-top: 0.24rem;
                    .trade-number {
                        padding: 0 0.24rem;
                        font-size: 0.32rem;
                        height: 0.88rem;
                        .ant-input-number {
                            font-size: 0.32rem;
                        }
                    }
                    .trade-max {
                        width: 0.92rem;
                        height: 0.48rem;
                        span {
                            font-size: 0.28rem;
                        }
                    }
                }
                .stake-period {
                    margin-top: 34px;
                    ul {
                        display: flex;
                        margin-top: 18px;
                        li {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 140px;
                            height: 60px;
                            cursor: pointer;
                            margin-right: 40px;
                            font-size: 20px;
                            color: #111111;
                            background: rgba(255, 255, 255, 0.39);
                            border: 1px solid #111111;
                            border-radius: 4px;
                            &.active {
                                color: #ffffff;
                                background: #111111;
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .stake-content-info {
                padding-top: 0.4rem;
                h3 {
                    font-size: 0.3rem;
                }
                .stake-equation {
                    font-size: 0.24rem;
                }
                .stake-note {
                    font-size: 0.3rem;
                    padding-top: 0.16rem;
                    padding-bottom: 0.1rem;
                    line-height: 0.48rem;
                }
                ul {
                    li {
                        font-size: 0.3rem;
                        line-height: 0.48rem;
                        margin-top: 0.1rem;
                    }
                }
            }
        }
        .withdraw-info {
            margin-top: 0.4rem;
            padding: 0.4rem;
            ul {
                li {
                    width: 2.85rem;
                    padding: 0.28rem 0 0.28rem 0.12rem;
                    h4 {
                        font-size: 0.28rem;
                        line-height: 0.4rem;
                    }
                    p {
                        padding-top: 0.12rem;
                        font-size: 0.28rem;
                        img {
                            padding-left: 0.2rem;
                        }
                    }
                    &:last-child {
                        margin-left: 0.2rem;
                    }
                }
            }
        }
        .position-total {
            margin: 0 0.4rem;
            padding: 0.32rem 0.4rem;
            label {
                font-size: 0.24rem;
                line-height: 0.28rem;
                padding-bottom: 0.16rem;
            }
            p {
                font-size: 0.32rem;
                line-height: 1;
            }
        }
        .position-info {
            margin: 0.32rem 0.4rem 0;
            background: rgba(113, 135, 242, 0.05);
            border-radius: 4px;
            padding: 0.24rem 0.16rem;
            .position-info-dl {
                .position-info-dt {
                    .position-info-dt-arrow {
                        width: 20px;
                    }
                    .position-info-title {
                        h2 {
                            font-size: 0.32rem;
                            line-height: 0.4rem;
                        }
                        p {
                            padding: 0 0.16rem;
                            height: 0.32rem;
                            font-size: 0.24rem;
                        }
                    }
                }
                .position-info-dd {
                    margin-bottom: 0.24rem;
                    width: 100%;
                    box-shadow: 0.12rem 0.12rem 0 0 #e2e6fc;
                    .ant-row {
                        padding: 0.4rem 0.24rem;
                        label {
                            font-size: 0.24rem;
                            line-height: 0.24rem;
                        }
                        p {
                            margin-top: 0.08rem;
                            font-size: 0.28rem;
                            line-height: 0.32rem;
                        }
                    }
                    .position-button {
                        padding: 0.24rem 0.4rem 0.24rem 0;
                        border-top: 1px solid rgba(0, 0, 0, 0.1);
                        text-align: right;
                        button {
                            border: none;
                            background: none;
                            padding: 0 0.32rem;
                            height: 0.52rem;
                            border-radius: 4px;
                            border: 1px solid rgba(33, 90, 233, 0.2);
                            font-size: 0.28rem;
                            font-family: AvertaStd-Semibold;
                            color: rgba(33, 90, 233, 0.5);
                            &:first-child {
                                margin-right: 0.32rem;
                            }
                        }
                    }
                    .position-switch {
                        padding: 12px 24px 12px 0;
                        border-top: 1px solid rgba(0, 0, 0, 0.1);
                        text-align: right;
                        button {
                            width: 40px;
                            height: 20px;
                            border-radius: 10px;
                            opacity: 0.8;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            background-color: transparent;
                            margin-left: 8px;
                            &:after {
                                width: 16px;
                                height: 16px;
                                border-radius: 16px;
                                background: #ccced0;
                            }
                            &.ant-switch-checked {
                                &:after {
                                    background: #7187f2;
                                }
                            }
                        }
                    }
                }
            }
            /deep/.ant-list {
                .ant-list-items {
                    padding-top: 0.28rem;
                    .ant-list-item {
                        border-bottom: none;
                        padding: 0;
                    }
                }
                .ant-list-empty-text {
                    padding: 0;
                }
            }
        }
        .history-info {
            margin-bottom: 0.8rem;
        }
    }
}
.m_stake_auto_modal {
    .enable_staking_title {
        font-size: 0.32rem;
        line-height: 0.4rem;
        text-align: left;
    }
    .enable_staking_content {
        font-size: 0.28rem;
        line-height: 0.34rem;
        text-align: left;
        margin: 0.4rem 0 0 0;
    }
    .enable_staking_btn_wrap {
        margin-top: 0.48rem;
        height: 0.8rem;
        .enable_staking_btn {
            height: 0.8rem;
            font-size: 0.32rem;
        }
    }
}
.m_stake_withdraw_modal {
    .enable_staking_title {
        font-size: 0.32rem;
        line-height: 0.4rem;
    }
    .wallet-balance {
        margin-top: 0.4rem;
        h3 {
            font-size: 0.24rem;
            line-height: 0.24rem;
        }
        p {
            font-size: 0.28rem;
            line-height: 0.32rem;
            small {
                font-size: 0.24rem;
            }
        }
    }
    .trade-input {
        margin-top: 0.24rem;
        .trade-number {
            padding: 0 0.24rem;
            font-size: 0.32rem;
            height: 0.88rem;
            .ant-input-number {
                font-size: 0.32rem;
            }
        }
        .trade-max {
            width: 0.92rem;
            height: 0.48rem;
            span {
                font-size: 0.28rem;
            }
        }
    }
    .enable_staking_btn_wrap {
        button {
            width: 100%;
            height: 0.72rem;
            margin-top: 0.4rem;
            box-shadow: 0.1rem 0.1rem 0 0 rgba(113, 135, 242, 0.2);
        }
        button[disabled] {
            color: rgba(0, 0, 0, 0.3);
            background: #d5d8e3;
            box-shadow: none;
        }
    }
    /deep/.ant-modal-content {
        .ant-modal-body {
            border-radius: 4px;
            border: 1px solid #000000;
            padding: 0.4rem 0.62rem 0.5rem 0.48rem;
            box-shadow: 0.12rem 0.12rem 0 0 #e2e6fc;
        }
    }
}
.m_stake_stake_modal {
    .enable_staking_title {
        font-size: 0.32rem;
        line-height: 0.4rem;
        text-align: left;
    }
    .stake-re-period {
        padding-top: 0.48rem;
        .stake-re-col {
            height: 1.32rem;
            border-radius: 0.2rem;
            h3 {
                font-size: 0.32rem;
                line-height: 0.4rem;
            }
            p {
                font-size: 0.28rem;
                line-height: 0.4rem;
                padding-top: 0.12rem;
            }
            &.active {
                background: rgba(113, 135, 242, 1);
                p {
                    color: #ffffff;
                }
            }
        }
    }
    .wallet-balance {
        margin-top: 0.56rem;
    }
    .trade-input {
        margin-top: 0.16rem;
        .trade-number {
            padding: 0 0.24rem;
            font-size: 0.32rem;
            height: 0.88rem;
            .ant-input-number {
                font-size: 0.32rem;
            }
        }
        .trade-max {
            width: 0.92rem;
            height: 0.48rem;
            span {
                font-size: 0.28rem;
            }
        }
    }
    .stake-button {
        button {
            height: 0.72rem;
            margin-top: 0.48rem;
            box-shadow: 0.1rem 0.1rem 0 0 rgba(113, 135, 242, 0.2);
            font-size: 0.32rem;
        }
    }
    .stake-auto {
        margin-top: 0.16rem;
        label {
            font-size: 0.24rem;
        }
    }
    .stake-date {
        display: flex;
        justify-content: space-between;
        margin-top: 0.24rem;
        label {
            font-size: 0.24rem;
        }
        p {
            font-size: 0.24rem;
        }
    }
    /deep/.ant-modal-content {
        .ant-modal-body {
            border-radius: 4px;
            border: 1px solid #000000;
            padding: 0.4rem 0.62rem 0.5rem 0.48rem;
            box-shadow: 0.12rem 0.12rem 0 0 #e2e6fc;
        }
    }
}
@media screen and (min-width: 820px) and (max-width: 1080px) {
    .stake-box {
        header {
            padding: 52px 3.67% 40px;
        }
    }
}
@media screen and (min-width: 1080px) and (max-width: 1280px) {
    .stake-box {
        header {
            padding: 52px 4% 40px;
        }
    }
}
@media screen and (min-width: 1280px) and (max-width: 1600px) {
    .stake-box {
        header {
            padding: 52px 6% 40px;
        }
    }
}
</style>
