import BigNumber from "bignumber.js";
import moment from "moment";
BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN })
const isNullOrEmpty = function (val) {
    if (val == null || val == "" || typeof (val) == undefined) {
        return true;
    } else {
        return false;
    }
}

const timeFormat = (value, format) => {
    if (/^\d+$/.test(value)) {
        return moment.unix(value).utc().format(format);
    } else {
        if(value=='-'){
            return 'Invalid Date';
        }
        return moment.utc(value).utc().format(format);
    }
};

const decimals = function (val, size = 4) {
    if (!val) {
        return val === 0 ? "0.0000" : "-";
    }
    return new BigNumber(val.toString()).toFixed(size);
}
const decimalsUnit = function (val, size = 3) {
    if (!val) {
        return val === 0 ? "0.0000" : "-";
    }
    let unit = ''
    if (val >= 1e3 && val < 1e6) {
        val = val / 1e3;
        unit = 'K'
        size = 1;
    }
    if (val >= 1e6 && val < 1e9) {
        val = val / 1e6;
        unit = 'M'
        size = 1;
    }
    if (val >= 1e9) {
        val = val / 1e9;
        unit = 'B';
        size = 1;
    }
    return new BigNumber(val.toString()).toFixed(size) + unit;
}
const decimalsFormat = function (val, size = 3) {
    if (!val) {
        return val === 0 ? 0 : "-";
    }
    return new BigNumber(new BigNumber(val.toString()).toFixed(size)).toFixed();
}
export {
    isNullOrEmpty,
    timeFormat,
    decimals,
    decimalsUnit,
    decimalsFormat
}
