<!--  -->
<template>
    <div class="flex-box">
        <a-select class="change-network" dropdownClassName="drop-network" :value="desireChainId" @change="switchNetwork">
            <a-select-option v-for="item in networkObj" :key="item.chainId" :value="item.chainId" :disabled="item.disabled">
                <div class="flex-box" :class="{ 'justify-content_flex-center': isMobile, 'pd-l12': !isMobile }">
                    <img :src="`/img/${item.nativeCurrency.name}.png`" width="22" />
                    <span class="network-span" v-if="!isMobile">
                        {{ item.chainName }}
                    </span>
                </div>
            </a-select-option>
        </a-select>
        <template v-if="account">
            <template v-if="desireChainId != chainId">
                <a-button @click="switchNetwork(desireChainId)" :loading="connectedLoading">
                    Wrong Network
                </a-button>
            </template>
            <template v-else>
                <a-popover placement="bottomRight">
                    <a-button :loading="connectedLoading" class="active-button">
                        {{ account | showAddress }}
                    </a-button>
                    <template slot="content">
                        <p @click="disConnect" class="color-0E1D51 ts-16 cursor"><a-icon type="disconnect" /><span class="pd-l8">Disconnect wallet</span></p>
                    </template>
                </a-popover>
            </template>
        </template>
        <a-button class="connection" v-else @click="onConnected" :loading="connectedLoading">
            Connect Wallet
        </a-button>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import { Network } from '@/config/constants';
import detectEthereumProvider from '@metamask/detect-provider';
export default {
    components: {},
    data() {
        return {
            connectedLoading: false,
            networkObj: Network,
        };
    },
    filters: {
        showAddress: function(text) {
            return text.substring(0, 6) + '...' + text.substring(38, 42);
        },
    },
    computed: {
        ...mapState({
            account: (state) => state.account,
            isMobile: (state) => state.isMobile,
            chainId: (state) => state.chainId,
            desireChainId: (state) => state.desireChainId,
        }),
    },
    watch: {
        mobile(val) {
            this.mobile = val;
        },
    },
    methods: {
        ...mapActions(['connected', 'updateAccount', 'switchNetwork']),
        disConnect() {
            this.updateAccount(null);
        },
        onConnected() {
            if (!(typeof window.ethereum !== 'undefined')) {
                message.error('install MetaMask first!');
            } else {
                this.connectedLoading = true;
                this.connected()
                    .then(async () => {
                        if (this.chainId != this.desireChainId) {
                            await this.switchNetwork(this.desireChainId);
                        }
                        this.connectedLoading = false;
                    })
                    .catch((err) => {
                        if (err.code === -32002) {
                            message.error(this.$t('wallet.openWallet'));
                        }
                        this.connectedLoading = false;
                    });
            }
        },
    },
    created() {},
    mounted() {
        let default_chainId;
        for (let _chainId in Network) {
            default_chainId = _chainId;
            break;
        }
        this.$store.commit('SET_desireChainId', default_chainId);
        detectEthereumProvider()
            .then(async (provider) => {
                if (!provider) {
                    return;
                }
                const ethereum = window.ethereum;
                ethereum.on('disconnect', () => {
                    this.updateAccount(null);
                });
                ethereum.on('chainChanged', (chainId) => {
                    this.$store.commit('SET_chainId', chainId);
                    if (this.networkObj[chainId]) {
                        window.location.reload();
                    }
                });
                ethereum.on('accountsChanged', (accounts) => {
                    console.log('accountsChanged', accounts);
                    // this.$store.dispatch("updateProduct", "DEFI");
                    if (accounts.length > 0) {
                        this.account && this.updateAccount(accounts[0]);
                    } else {
                        this.updateAccount(null);
                    }
                });
                let chainId = await ethereum.request({
                    method: 'eth_chainId',
                });
                this.$store.commit('SET_chainId', chainId);
                if (this.networkObj[chainId]) {
                    this.$store.commit('SET_desireChainId', chainId);
                } else {
                    this.$store.commit('SET_desireChainId', default_chainId);
                }
                ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
                    if (accounts && accounts.length > 0) {
                        this.$store.dispatch('connected');
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });
    },
};
</script>
<style lang="scss">
.network-span {
    margin-left: 8px;
}
.ant-select-dropdown {
    .ant-select-dropdown-menu-item {
        padding: 0;
    }
}
</style>
<style lang="scss" scoped>
button {
    width: 210px;
    height: 56px;
    color: white;
    background: #0e1d51;
    font-size: 20px;
    font-family: AvertaStd-Regular;
    border: none;
    &:hover,
    &:focus,
    &:active {
        background: linear-gradient(90deg, #7b7cf2 0%, #2dcaed 100%);
        color: white;
    }
    &.active-button {
        background: linear-gradient(90deg, #7b7cf2 0%, #2dcaed 100%);
        color: white;
    }
}
.change-network {
    margin-right: 40px;
    width: 204px;
    height: 56px;
    font-size: 20px;
    border: none;
    color: #111111;
    /deep/ .ant-select-selection--single {
        height: 56px;
        border: 1px solid rgba(213, 216, 227, 1);
        border-radius: 4px;
        background: transparent;
        .ant-select-selection__rendered {
            line-height: 54px;
            margin-left: 20px;
            margin-right: 40px;
            display: flex;
            justify-content: center;
        }

        .ant-select-arrow {
            right: 20px;
            color: #111111;
            display: none;
        }
    }
}
</style>
