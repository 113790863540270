import { BigNumber } from 'ethers';

const MaxUint256 = BigNumber.from('0x7fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');

//env:kcctest
// const STAKING_CONTRACT = '0x82021e9437EB2bD09b0F903885D240141B3E5038';
// const NOTE_CONTRACT = '0x4744c92726Ad673C0627B810199F8A0f0e2aD883';
// const CLH = '0x708a9aCc19B3C2D2336711f95458DC1ED3B3A724';

//env:testing-mainnet
// const STAKING_CONTRACT = '0x6Fd71507Eb4d9396dB505Fd79Acdf2D8998931fe';
// const NOTE_CONTRACT = '0x08071217096E42013E58a83F7CB9d74C8Aee180e';
// const CLH = '0xcB27F71C21A7853525E499bc0B662aA038017881';

//env:mainnet
const STAKING_CONTRACT = '0xe6559ba92bb34aedf9de67cb523494c8eadf954b';
const NOTE_CONTRACT = '0xD211771f2A21Eb326bF3Bfe8EF8289C2cF4d7436';
const CLH = '0xD7D8f3b8Bc8bC48d3AcC37879EABA7b85889FA52';

const Network = {
    '0x1': {
        chainId: '0x1',
        chainName: 'ETH',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://mainnet.infura.io/v3/efae3a02063a4818bc512ec1075cd8a2'],
        blockExplorerUrls: ['https://etherscan.io/'],
        iconUrls: [],
        chainType: '1',
        chainNameMobile: 'ETH',
    },
    // '0x142': {
    //     chainId: '0x142',
    //     chainName: 'KCC',
    //     nativeCurrency: {
    //         name: 'KCS',
    //         symbol: 'KCS',
    //         decimals: 18,
    //     },
    //     rpcUrls: ['https://rpc-testnet.kcc.network'],
    //     blockExplorerUrls: ['https://scan-testnet.kcc.network'],
    //     iconUrls: [],
    //     chainType: '1',
    //     chainNameMobile: 'KCC',
    // },
    // '0x539': {
    //     chainId: '0x539',
    //     chainName: 'KCC',
    //     nativeCurrency: {
    //         name: 'KCS',
    //         symbol: 'KCS',
    //         decimals: 18,
    //     },
    //     rpcUrls: ['http://127.0.0.1:7545'],
    //     blockExplorerUrls: [],
    //     iconUrls: [],
    //     chainType: '1',
    //     chainNameMobile: 'KCC',
    // },
};
export { MaxUint256, NOTE_CONTRACT, STAKING_CONTRACT, Network, CLH };
