import Vue from 'vue';
import Vuex from 'vuex';
import { ethers } from 'ethers';
import { message } from 'ant-design-vue';
import { getStorage, setStorage } from '@/utils';
import { Network, CLH, STAKING_CONTRACT } from '@/config/constants';
import METAERC20 from '@/abi/ERC20.json';
import METAStaking from '@/abi/Staking.json';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        device: 'desktop',
        account: null,
        userBalance: 0,
        chainId: null,
        desireChainId: null,
        currentTheme: getStorage('currentTheme') || '',
        isMobile: false,
        erc20Contract: null,
        stakingContract: null,
        signer: null,
        provider: null,
    },
    getters: {
        chainName(state) {
            let chain = Network[state.chainId];
            if (chain) {
                return chain['chainName'];
            } else {
                return '';
            }
        },
        chainType(state) {
            let chain = Network[state.chainId];
            if (chain) {
                return chain['chainType'];
            } else {
                return '';
            }
        },
    },
    mutations: {
        SET_Contract: (state, { erc20Contract, stakingContract }) => {
            state.erc20Contract = erc20Contract;
            state.stakingContract = stakingContract;
        },
        SET_ACCOUNT: (state, account) => {
            state.account = account;
        },
        SET_chainId: (state, chainId) => {
            state.chainId = chainId;
        },
        SET_desireChainId: (state, desireChainId) => {
            state.desireChainId = desireChainId;
        },
        SET_THEME: (state, theme) => {
            state.currentTheme = theme;
            setStorage('currentTheme', theme);
        },
        TOGGLE_DEVICE: (state, device) => {
            state.device = device;
        },
        SET_userBalance: (state, userBalance) => {
            state.userBalance = userBalance;
        },
        set_ISMOBILE: (state, value) => {
            state.isMobile = value;
        },
        set_signer: (state) => {
            let _provider;
            if (window.ethereum) {
                _provider = new ethers.providers.Web3Provider(window.ethereum);
            } else if (window.web3) {
                _provider = new ethers.providers.Web3Provider(window.web3.currentProvider);
            }
            const provider = _provider;
            const signer = provider ? provider.getSigner() : null;
            state.signer = signer;
            state.provider = provider;
        },
    },
    actions: {
        updateTheme({ commit }, theme) {
            commit('SET_THEME', theme);
        },
        updateMobile({ commit }, theme) {
            commit('set_ISMOBILE', theme);
        },
        async switchNetwork({ commit }, value) {
            commit('SET_desireChainId', value);
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: value }],
                });
                commit('SET_chainId', value);
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                try {
                    const item = Network[value];
                    let paramsArry = [
                        {
                            chainId: item.chainId,
                            chainName: item.chainName,
                            rpcUrls: item.rpcUrls,
                            nativeCurrency: item.nativeCurrency,
                            blockExplorerUrls: item.blockExplorerUrls ? item.blockExplorerUrls : null,
                        },
                    ];
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: paramsArry,
                    });
                    commit('SET_chainId', value);
                } catch (addError) {
                    console.log(addError);
                }
            }
        },
        async connected({ commit, state }) {
            try {
                if (!window.ethereum) {
                    message.error('install MetaMask first!');
                    return false;
                }
                commit('set_signer');
                let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const account = accounts[0];
                const erc20Contract = new ethers.Contract(CLH, METAERC20, state.signer);
                const stakingContract = new ethers.Contract(STAKING_CONTRACT, METAStaking, state.signer);
                commit('SET_Contract', { erc20Contract, stakingContract });
                this.dispatch('updateAccount', account);
            } catch (err) {
                return false;
            }
        },
        async updateAccount({ commit, state }, account) {
            commit('SET_ACCOUNT', account);
            const erc20Contract = this.state.erc20Contract;
            const provider = state.provider;
            if (account) {
                let filterFrom = erc20Contract.filters.Transfer(account);
                let filterTo = erc20Contract.filters.Transfer(null, account);
                provider.off(filterFrom);
                provider.off(filterTo);
                provider.on(filterFrom, () => {
                    erc20Contract.balanceOf(account).then((userBalance) => {
                        commit('SET_userBalance', userBalance.toString());
                    });
                });
                provider.on(filterTo, () => {
                    erc20Contract.balanceOf(account).then((userBalance) => {
                        commit('SET_userBalance', userBalance.toString());
                    });
                });
                erc20Contract.balanceOf(account).then((userBalance) => {
                    commit('SET_userBalance', userBalance.toString());
                });
            } else {
                const erc20Contract = this.state.erc20Contract;
                let filterFrom = erc20Contract.filters.Transfer(account);
                let filterTo = erc20Contract.filters.Transfer(null, account);
                provider.off(filterFrom);
                provider.off(filterTo);
                commit('SET_userBalance', 0);
            }
        },
    },
});
