<template>
  <div class="home-footer" ref="foot">
    <div class="home-footer-info">
      <div class="home-footer-left">
        <a href="https://www.cleardao.com" rel="nofollow" target="_blank" class="home-footer-logo"><img src="@/assets/images/logo-white.svg" /></a>
        <ul>
          <li>
            <a href="https://medium.com/@clear_dao" rel="nofollow" target="_blank">
            </a>
          </li>
          <li>
            <a href="https://twitter.com/clear_dao" rel="nofollow" target="_blank">
            </a>
          </li>
          <li>
            <a href="https://t.me/cleardao" rel="nofollow" target="_blank">
            </a>
          </li>
        </ul>
      </div>
      <div class="home-footer-right">
        <!-- about -->
        <ul>
          <li>About</li>
          <li>
            Docs
          </li>
          <li>
            <a href="https://medium.com/@clear_dao" rel="nofollow" target="_blank">
              Blog
            </a>
          </li>
          <li>
            <a href="https://faq.cleardao.com/" rel="nofollow" target="_blank">
              FAQ
            </a>
          </li>
          <li>
            <a href="mailto:hello@cleardao.com" rel="nofollow" target="_blank">
              Contact
            </a>
          </li>
          <li>
            <a href="https://www.cleardao.com/assets/Clear_Branding_Assets.zip" rel="nofollow" target="_blank">
              Brand Assets
            </a>
          </li>
        </ul>
        <!-- Templates -->
        <ul>
          <li>Templates</li>
          <li>
            <a href="https://www.cleardao.com/#templates" target="_blank" rel="nofollow">
              Options
            </a>
          </li>
          <li>
            <a href="https://www.cleardao.com/#templates" target="_blank" rel="nofollow">
              Notes
            </a>
          </li>
          <li>
            <a href="https://www.cleardao.com/#templates" target="_blank" rel="nofollow">
              Futures
            </a>
          </li>
          <li>
            <a href="https://www.cleardao.com/#templates" target="_blank" rel="nofollow">
              Swaps
            </a>
          </li>
        </ul>
        <!-- Explore -->
        <ul>
          <li>Explore</li>
          <li>
            <a  href="https://www.cleardao.com/indices" rel="nofollow" target="_blank">
              CLEARIndex
            </a>
          </li>
          <li>
            <a  href="https://www.cleardao.com/eip" rel="nofollow" target="_blank">
              EIP-5187
            </a>
          </li>
        </ul>
        <!-- Governance -->
        <ul>
          <li>Governance</li>
          <li>
            <a href="https://staking.cleardao.com" rel="nofollow">
              CLH Staking
            </a>
          </li>
          <li>
            <a href="https://claim.cleardao.com" rel="nofollow" target="_blank" >
              CLH Claim
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="home-footer-bottom">
      <div>
        <p><span class="pd-r8">© 2023 ClearDAO</span>|<a class="pd-l8" href="https://derivstudio.com" rel="nofollow" target="_blank">Powered by Derivstudio</a></p>
        <ul>
          <li><a>Disclaimer</a></li>
          <li><a>Privacy Policy</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeFooter",
  methods: {
    goAbout() {
      this.$parent.$refs["about"].scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    },
    goRoadMap() {
      this.$parent.$refs["roadMap"].scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    },
    comingSoon() {
      this.$notification["info"]({
        message: "Coming Soon",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #ffffff;
}
.home-popover{
  p{
    display: flex;
    a{
      color: #000000;
      min-width: 120px;
      height: 26px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:hover{
        font-size: 16px;
        background: rgba(230, 247, 255, 1);
        font-family: "AvertaStd-Semibold";
      }
    }
  }
}
.home-footer {
  height: 379px;
  background: #0E1D51;
  width: 100%;
  .home-footer-info{
    padding: 0 7.5%;
    height: 314px;
    margin:0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    .home-footer-left{
      padding-top: 54px;
      .home-footer-logo {
        display: block;
      }
      ul{
        display: flex;
        margin-top:36px;
        li{
          margin-right: 26px;
          &:nth-child(1){
            a{
              display: inline-block;
              width:  27px;
              height: 22px;
              background: url("~@/assets/images/foot-3-1.svg") no-repeat center;
              &:hover{
                background: url("~@/assets/images/foot-3-2.svg") no-repeat center;
              }
            }
          }
          &:nth-child(2){
            a{
              display: inline-block;
              width:  27px;
              height: 22px;
              background: url("~@/assets/images/foot-4-1.svg") no-repeat center;
              &:hover{
                background: url("~@/assets/images/foot-4-2.svg") no-repeat center;
              }
            }
          }
          &:nth-child(3){
            a{
              display: inline-block;
              width:  27px;
              height: 22px;
              background: url("~@/assets/images/foot-1-1.svg") no-repeat center;
              &:hover{
                background: url("~@/assets/images/foot-1-2.svg") no-repeat center;
              }
            }
          }

        }
      }
    }
    .home-footer-right{
      display: flex;
      padding-top: 48px;
      position: relative;
      &:after{
        content: "";
        position: absolute;
        top: 84px;
        width: 100%;
        height: 1px;
        background: rgba(255,255,255,0.1);
      }
      ul{
        margin-left: 124px;
        position: relative;
        &:first-child{
          margin-left: 0;
        }
        li{
          font-size: 16px;
          color: #3B4360;
          margin-bottom: 9px;
          cursor: default;
          &:first-child{
            margin-bottom: 22px;
            font-family: "AvertaStd-Bold";
            font-size: 18px;
            color: #868EA8;
          }
          a{
            font-size: 14px;
            color: #C0CEF9;
            &:hover{
              color: #2DCBED;
            }
          }
        }
      }
      p{
        color: #2DCBED;
        font-size: 14px;
        line-height: 20px;
        padding-top: 20px;
        margin-right: 24px;
        a{
          color: #2DCBED;
        }
      }
    }
  }
  .home-footer-bottom{
    padding: 0 7.5%;
    min-width: 1020px;
    margin:0 auto;
    background: rgba(0, 0, 0, 0.1);
    div{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
    }
    p{
      font-size: 14px;
      color: #868EA8;
      a{
        font-size: 14px;
        color: #C0CEF9;
      }
    }
    ul{
      display: flex;
      li{
        margin-left: 32px;
        a{
          font-size: 14px;
          color: #868EA8;
          cursor: default;
        }
      }
    }
  }
}
@media screen and (min-width: 820px) and (max-width: 1080px) {
  .home-footer{
    .home-footer-info,.home-footer-bottom{
      padding: 0 3.67%;
    }
  }
}
@media screen and (min-width: 1080px) and (max-width: 1280px) {
  .home-footer{
    .home-footer-info,.home-footer-bottom{
      padding: 0 4%;
    }
  }
}
@media screen and (min-width: 1280px) and (max-width: 1600px){
  .home-footer{
    .home-footer-info,.home-footer-bottom{
      padding: 0 6%;
    }
  }
}
</style>
