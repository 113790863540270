import request from '../utils/request'

export function transStatus(data) {
    return request({
        url: 'get-trans-status',
        method: 'get',
        direct: 1,
        params: data
    });
}
export function positions(data) {
    return request({
        url: 'positions',
        method: 'get',
        direct: 1,
        params: data
    });
}
export function positionsMore(data) {
    return request({
        url: 'position-more',
        method: 'get',
        direct: 1,
        params: data
    });
}
export function productList(data) {
    return request({
        url: 'product-list',
        method: 'get',
        direct: 1,
        params: data
    });
}
export function updateAutoReinvestment(data) {
    return request({
        url: 'update-auto-reinvestment',
        method: 'post',
        direct: 1,
        data: data
    });
}
export function signature(data) {
    return request({
        url: 'signature',
        method: 'post',
        direct: 1,
        data: data
    });
}
export function signatureBatch(data) {
    return request({
        url: 'signature-batch',
        method: 'post',
        direct: 1,
        data: data
    });
}
