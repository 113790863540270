let basePath = '/api/'
switch (process.env.NODE_ENV) {
    //product
    case 'production':
        basePath = '/api/';
        break;
    //test
    default:
        // basePath = 'https://staking.bbbing.co/api/';
        basePath = 'https://staking.cleardao.com/api/';
        break;
}
module.exports = {
    basePath
}