<template>
  <div id="app">
    <stake/>
  </div>
</template>

<script>
import { mobileRem } from "@/common/rem";
import stake from "./components/stake";
export default {
  name: 'App',
  components: {
    stake
  },
  created() {
    mobileRem();
  }
}
</script>

<style>
</style>
