import { ethers } from "ethers";
import { transStatus } from '@/api';
let _provider;
if (window.ethereum) {
    _provider = new ethers.providers.Web3Provider(window.ethereum);
} else if (window.web3) {
    _provider = new ethers.providers.Web3Provider(window.web3.currentProvider);
}
const provider = _provider;
export async function waitForTransaction(hash) {
    let receipt = await provider.waitForTransaction(hash);
    return await new Promise((resolve, reject) => {
        let i = 0;
        const interval = setInterval(() => {
            i++;
            transStatus({ trans_hash: hash }).then(({ data }) => {
                if (data === 1) {
                    resolve(receipt);
                    clearInterval(interval);
                }
                console.log({ i, data });
                if (i >= 1000) {
                    reject(receipt);
                }
            }).catch(() => {
                console.log({ i });
                if (i >= 1000) {
                    reject(receipt);
                }
            });
        }, 1000);
    });
}